import { useEffect } from 'react';
import { Button, Flex, Text } from '@mantine/core';

import { userApi } from '~/api';
import { extractResponseError } from '~/api/utils';
import { useAppContext } from '~/contexts/app';
import { useRequestStates } from '~/hooks';
import useAnalytics from '~/hooks/useAnalytics';

import { PageContentLoader } from '../molecules';

function AppShell({ children }) {
  const analytics = useAnalytics();
  const { user, setUser } = useAppContext();
  const [fetchUserRequestStates, fetchUserRequestHandlers] = useRequestStates();

  const fetchUser = async () => {
    try {
      fetchUserRequestHandlers.pending();
      const resp = await userApi.fetchUser();
      const user = resp.result;
      setUser(user);
      fetchUserRequestHandlers.fulfilled(user);
    } catch (error) {
      const errorObj = extractResponseError(error);
      fetchUserRequestHandlers.rejected(errorObj);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (user) {
      analytics.identify(user.id, {
        name: user.name,
        email: user.email,
        phone_number: user.phoneNumber,
        role: user.role,
        org: {
          id: user.orgId,
        },
        // TODO: designation, org
      });
    }
  }, [user]);

  if (fetchUserRequestStates.fulfilled) {
    return children;
  } else if (fetchUserRequestStates.pending) {
    return <PageContentLoader />;
  } else if (fetchUserRequestStates.rejected) {
    const { errorMessage, statusCode } = fetchUserRequestStates.error;

    if (![400, 401].includes(statusCode)) {
      // Any error other than 400 and 401 requires the error UI to be shown

      return (
        <Flex direction="column" align="center" justify="center" py="56px">
          <Text>{errorMessage || 'Failed to load page content. Please try again.'}</Text>
          <Button
            mt="lg"
            size="sm"
            onClick={fetchUser}
            styles={{
              root: {
                background: '#5A47D7',
                border: '1px solid #7b6cdf',
              },
            }}
          >
            Try again
          </Button>
        </Flex>
      );
    } else {
      /* We can continue rendering children.
       * Login check would automatically happen at route level
       */
      return children;
    }
  }
}

export default AppShell;
