// Common
export const DATE_FORMATS = {
  default: 'd MMM yy, hh:mm a',
  defaultWithSeconds: 'd MMM yy, hh:mm:ss a',
  defaultWithTimezone: 'd MMM yyyy, hh:mm a xxx',
  defaultWithFullYear: 'd MMM yyyy, hh:mm a',
  date: 'dd MMM yyyy',
  iso8601: "yyyy-MM-dd'T'HH:mm:ssXXX",
  time: 'hh:mm a',
  timeWithSeconds: 'hh:mm:ss a',
  nativeInput: `yyyy-MM-dd'T'HH:mm:ss`,
  csvDate: 'yyyy-MM-dd',
  csvTime: 'HH:mm:ss',
  csvDateAndTime: 'yyyy-MM-dd HH:mm:ss',
};

export const REQUEST_STATES = {
  IDLE: 'IDLE',
  PENDING: 'PENDING',
  FULFILLED: 'FULFILLED',
  REJECTED: 'REJECTED',
};

export const MODAL_IDS = {
  V0: {
    JOB_APPLICATIONS_FILTER_QUERIES: 'V0_JOB_APPLICATIONS_FILTER_QUERIES',
    REPORT_ERROR: 'V0_REPORT_ERROR',
    TALENT_POOL_SEARCH_HISTORY: 'V0_TALENT_POOL_SEARCH_HISTORY',
  },
};

export const JOB_TYPES_MAP = {
  FULL_TIME: 'Full Time',
  PART_TIME: 'Part Time',
  CONTRACT: 'Contract',
  INTERNSHIP: 'Internship',
  FREELANCE: 'Freelance',
  CONTRACT_TO_HIRE: 'Contract to Hire',
};

export const SUPPORT_EMAIL = 'support@kello.ai';

export const DEFAULT_SEARCH_VERSION = '3';
