import { createContext, useCallback, useContext, useState } from 'react';
import { useImmer } from 'use-immer';

const AppContext = createContext();
AppContext.displayName = 'AppContext';

const AppContextProvider = ({ children }) => {
  const [user, setUser] = useImmer(null);
  const [isDevMode, setIsDevMode] = useState(false);
  const [isBetaMode, setIsBetaMode] = useState(true);
  const [applicationStatuses, setApplicationStatuses] = useImmer({});
  const [isNavbarExpanded, setIsNavbarExpanded] = useState(true);

  const navbarWidth = useCallback(() => {
    if (isNavbarExpanded) {
      return '252px';
    }
    return '76px';
  }, [isNavbarExpanded])();

  const exposed = {
    user,
    setUser,

    isDevMode,
    setIsDevMode,

    isBetaMode,
    setIsBetaMode,

    applicationStatuses,
    setApplicationStatuses,

    navbarWidth,
    isNavbarExpanded,
    setIsNavbarExpanded,
  };

  return <AppContext.Provider value={exposed}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);

export default AppContextProvider;
