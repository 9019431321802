import '@fontsource/open-sans/latin.css';
import '@fontsource/roboto-mono/latin.css';
import '@fontsource/roboto/latin.css';
import '@fontsource/inter/latin.css';
import '@fontsource/manrope/latin.css';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dropzone/styles.css';
import { lazy, Suspense } from 'react';
import { createTheme, MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import * as Sentry from '@sentry/react';
// eslint-disable-next-line no-unused-vars
import { BrowserHistory } from 'history';
import { Route, Routes } from 'react-router-dom';
import { AnalyticsProvider } from 'use-analytics';
import { GoogleOAuthProvider } from '@react-oauth/google';

import AppRoot from '~/components/core/AppRoot';
import theme from '~/styles/theme/';
import { LoaderWithOverlay } from '~/components/molecules';
import AppContextProvider from '~/contexts/app';

import AppShell from './components/core/AppShell';
import { MODAL_IDS } from './constants';
import { v0Modals } from './components/shared/modals';
import { initAnalytics } from './lib/analytics';
import LoginRequiredRoute from './components/routes/LoginRequiredRoute';
import { GOOGLE_AUTH_CLIENT_ID } from './config';

const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const ProductPage = lazy(() => import('./pages/ProductPage'));
const LoginPage = lazy(() => import('./pages/auth/Login'));
const OrgHomePage = lazy(() => import('./pages/v0/orgHome'));
const JobDetailsPage = lazy(() => import('./pages/v0/jobDetails'));
const TalentPoolPage = lazy(() => import('./pages/v0/talentPool'));
const TermsAndConditionsPage = lazy(() => import('./pages/TermsAndConditions'));
const PrivacyPolicyPage = lazy(() => import('./pages/PrivacyPolicy'));

const analytics = initAnalytics();

const mantineTheme = createTheme({
  ...theme,
  fontFamily: "'Manrope', sans-serif",
});

/**
 *
 * @param {{history: BrowserHistory}} props
 */
export default function App({ history }) {
  return (
    <Sentry.ErrorBoundary showDialog={false}>
      <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_ID}>
        <MantineProvider theme={mantineTheme} withNormalizeCSS withGlobalStyles>
          <AnalyticsProvider instance={analytics}>
            <Notifications position="top-right" />
            <ModalsProvider
              modals={{
                [MODAL_IDS.V0.JOB_APPLICATIONS_FILTER_QUERIES]: v0Modals.JobApplicationsFilterQueriesModal,
                [MODAL_IDS.V0.REPORT_ERROR]: v0Modals.ReportErrorModal,
                [MODAL_IDS.V0.TALENT_POOL_SEARCH_HISTORY]: v0Modals.TalentPoolHistoryModal,
              }}
            >
              <AppContextProvider>
                <AppRoot history={history}>
                  <AppShell>
                    <Suspense fallback={<LoaderWithOverlay />}>
                      <Routes>
                        <Route path="/" element={<ProductPage />} />
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                        <Route path="/app" element={<LoginRequiredRoute />}>
                          <Route index element={<OrgHomePage />} />
                          <Route path="job/:jobSlug" element={<JobDetailsPage />} />
                          <Route path="talent-pool" element={<TalentPoolPage />} />
                        </Route>
                        <Route path="*" element={<NotFoundPage />} />
                      </Routes>
                    </Suspense>
                  </AppShell>
                </AppRoot>
              </AppContextProvider>
            </ModalsProvider>
          </AnalyticsProvider>
        </MantineProvider>
      </GoogleOAuthProvider>
    </Sentry.ErrorBoundary>
  );
}
