import * as constants from '~/constants';

const theme = {
  fontFamily: 'Roboto, sans serif',
  other: {
    dateFormats: constants.DATE_FORMATS,
    fontWeights: {
      regular: '400',
      medium: '500',
      semiBold: '600',
      bold: '700',
    },
  },
};

export default theme;
