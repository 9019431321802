import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Global, ThemeProvider } from '@emotion/react';
import { useMantineTheme } from '@mantine/core';
// import * as Sentry from '@sentry/react';
// eslint-disable-next-line no-unused-vars
import { BrowserHistory } from 'history';

/**
 *
 * @param {{history: BrowserHistory}} props
 */
function AppRoot({ children, history }) {
  const theme = useMantineTheme();
  // const userQuery = useGetUserQuery();
  // const user = userQuery.data?.user;

  const [isAppUpdateAvailable, setIsAppUpdateAvailable] = useState(false);

  // useEffect(() => {
  //   if (user) {
  //     Sentry.setUser({ id: user.id, email: user.email, name: user.name });
  //   } else {
  //     Sentry.configureScope((scope) => scope.setUser(null));
  //   }
  // }, [user]);

  // Listen for `UPDATE_AVAILABLE` broadcast event
  useEffect(() => {
    if ('BroadcastChannel' in window) {
      const channel = new BroadcastChannel('sw-broadcast-update');
      channel.onmessage = (event) => {
        if (event.data.type === 'UPDATE_AVAILABLE') {
          setIsAppUpdateAvailable(true);
        }
      };
    }
  }, []);

  // Do a full page reload during navigation if an app update is available
  useEffect(() => {
    const unregister = history.listen(({ location: { pathname, search, hash } }) => {
      if (isAppUpdateAvailable) {
        const url = `${pathname}${search}${hash}`;
        window.location.href = url;
      }
    });
    return unregister;
  }, [isAppUpdateAvailable]);

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Kello</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="theme-color" content="#000000" />
        {/* <link rel="icon" href={faviconSvgFile} /> */}
        {/* <link rel="alternate icon" href={faviconIcoFile} /> */}
        {/* <link rel="mask-icon" href={maskIconSvgFile} color="#000000" /> */}
      </Helmet>
      <Global />
      {children}
    </ThemeProvider>
  );
}

export default AppRoot;
