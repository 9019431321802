// import { IconBookmark, IconBookmarkFilled } from '@tabler/icons-react';
import { Button, Flex, Spoiler, Stack, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import plur from 'plur';
import { format } from 'date-fns';

import { talentPoolApi } from '~/api';
import { extractResponseError } from '~/api/utils';
import { PageContentLoader } from '~/components/molecules';
import { DATE_FORMATS } from '~/constants';
import { useRequestStates } from '~/hooks';
import { notifyError, notifySuccess } from '~/utils/notification.utils';

import * as Styles from './TalentPoolSearchHistoryModal.styled';

const SearchHistoryPrompt = ({ searchHistory, onUseQuery, ...rest }) => {
  const { id: searchHistoryId, parameters, isSaved } = searchHistory;
  const [isSearchSaved, setIsSearchSaved] = useState(isSaved || false);
  // eslint-disable-next-line no-unused-vars
  const [saveTalentPoolHistoryRequestStates, saveTalentPoolHistoryRequestHandlers] = useRequestStates();

  // eslint-disable-next-line no-unused-vars
  const toggleSaveSearchHistoryBtnClick = async () => {
    try {
      saveTalentPoolHistoryRequestHandlers.pending();
      const payload = {
        isSaved: !isSearchSaved,
      };
      const resp = await talentPoolApi.updateTalentPoolHistory({
        searchHistoryId,
        payload,
      });
      saveTalentPoolHistoryRequestHandlers.fulfilled(resp.result);
      const { isSaved: isSearchHistorySaved } = resp.result;
      setIsSearchSaved(isSearchHistorySaved);
      const saveSuccessMessage = 'Saved the search.';
      const unsaveSuccessMessage = 'Unsaved the search.';
      notifySuccess({
        message: isSearchHistorySaved ? saveSuccessMessage : unsaveSuccessMessage,
      });
    } catch (error) {
      const { errorMessage } = extractResponseError(error);
      saveTalentPoolHistoryRequestHandlers.rejected(errorMessage);
      notifyError({
        message: 'Something went wrong. Please try again later.',
      });
    }
  };

  return (
    <Styles.PromptCard {...rest}>
      <Stack gap="xs" mt="4px" miw="0">
        {parameters.search ? (
          <Spoiler
            maxHeight={40}
            showLabel="Show more"
            hideLabel="Hide"
            styles={{
              control: {
                color: '#333',
                textDecoration: 'underline',
                fontSize: '10px',
              },
            }}
          >
            <Text size="sm" title={parameters.search}>
              Query: {parameters.search}
            </Text>
          </Spoiler>
        ) : null}
        {parameters.companies?.length ? (
          <Text size="sm">
            {plur('Company', parameters.companies.length)}: {parameters.companies.join(', ')}
          </Text>
        ) : null}
        {parameters.sourcedBy?.length ? <Text size="sm">Sourced By: {parameters.sourcedBy.join(', ')}</Text> : null}
        {parameters.sourcedFrom?.length ? (
          <Text size="sm">Sourced From: {parameters.sourcedFrom.join(', ')}</Text>
        ) : null}
        {parameters.sourcedAt?.values?.length ? (
          <Text size="sm">
            Applied On: From {format(new Date(parameters.sourcedAt.values[0]), DATE_FORMATS.date)} to{' '}
            {format(new Date(parameters.sourcedAt.values[1]), DATE_FORMATS.date)}
          </Text>
        ) : null}
      </Stack>
      <Flex direction="column" align="flex-end" ml="sm" style={{ flexShrink: 0 }}>
        <Button
          size="xs"
          onClick={onUseQuery}
          styles={{
            root: {
              background: '#5A47D7',
              border: '1px solid #7b6cdf',
            },
          }}
        >
          Use
        </Button>
        {/* <Button
          mt="xs"
          size="compact-xs"
          variant="outline"
          onClick={toggleSaveSearchHistoryBtnClick}
          loading={saveTalentPoolHistoryRequestStates.pending}
          styles={{
            root: {
              border: '1px solid #7b6cdf',
            },
            label: {
              color: '#5A47D7',
            },
          }}
          // rightSection={
          //   isSearchSaved ? <IconBookmarkFilled size={16} stroke={1.5} /> : <IconBookmark size={16} stroke={1.5} />
          // }
        >
          {isSearchSaved ? 'Saved' : 'Save'}
        </Button> */}
      </Flex>
    </Styles.PromptCard>
  );
};

const TalentPoolSearchHistoryModal = ({ innerProps, context, id }) => {
  const { formInputFieldKeys, onUseQuery } = innerProps;
  const [pageNumber, setPageNumber] = useState(0);
  const [searchHistories, setSearchHistories] = useState([]);
  const [fetchTalentPoolSearchHistoryRequestStates, fetchTalentPoolSearchHistoryRequestHandlers] = useRequestStates();
  const [loadMoreTalentPoolSearchHistoryRequestState, loadMoreTalentPoolSearchHistoryRequestHandlers] =
    useRequestStates();

  const handleUseQuery = ({ searchHistory, sequence }) => {
    const { parameters } = searchHistory;

    const formData = {
      [formInputFieldKeys.SEARCH]: parameters?.search || '',
      [formInputFieldKeys.COMPANIES]: parameters?.companies || [],
      [formInputFieldKeys.SOURCED_BY]: parameters?.sourcedBy || [],
      [formInputFieldKeys.SOURCED_FROM]: parameters?.sourcedFrom || [],
      [formInputFieldKeys.APPLICATION_DATE_RANGE]: parameters?.sourcedAt?.values.length
        ? parameters?.sourcedAt?.values.map((v) => (v ? new Date(v) : null))
        : [null, null],
    };

    onUseQuery({ formData, searchHistory, sequence });
    context.closeModal(id);
  };

  const fetchTalentPoolSearchHistory = async () => {
    const requestHandler =
      pageNumber > 1 ? loadMoreTalentPoolSearchHistoryRequestHandlers : fetchTalentPoolSearchHistoryRequestHandlers;

    try {
      requestHandler.pending();
      const payload = {
        pageNumber: pageNumber + 1,
        pageSize: 5,
      };
      const resp = await talentPoolApi.fetchTalentPoolHistory(payload);
      const { history, totalCount } = resp.result;
      setSearchHistories((p) => [...p, ...history]);
      setPageNumber((p) => p + 1);
      requestHandler.fulfilled({ history, totalCount });
    } catch (error) {
      const { errorMessage } = extractResponseError(error);
      requestHandler.rejected(errorMessage);
    }
  };

  useEffect(() => {
    fetchTalentPoolSearchHistory();
  }, []);

  let nodeToRender;
  if (fetchTalentPoolSearchHistoryRequestStates.fulfilled) {
    nodeToRender = (
      <>
        <Stack gap="xs" mt="xs">
          {searchHistories.length ? (
            searchHistories.map((searchHistory, idx) => (
              <SearchHistoryPrompt
                key={searchHistory.id}
                searchHistory={searchHistory}
                onUseQuery={() => handleUseQuery({ searchHistory, sequence: idx + 1 })}
              />
            ))
          ) : (
            <Flex justify="center" mt="24px">
              <Text size="xs" color="#999">
                No history found
              </Text>
            </Flex>
          )}
        </Stack>
        {searchHistories.length <
        (fetchTalentPoolSearchHistoryRequestStates.data?.totalCount ||
          loadMoreTalentPoolSearchHistoryRequestState.data?.totalCount) ? (
          loadMoreTalentPoolSearchHistoryRequestState.pending ? (
            <PageContentLoader />
          ) : (
            <Flex justify="center" mt="24px">
              <Button
                size="xs"
                onClick={fetchTalentPoolSearchHistory}
                styles={{
                  root: {
                    background: '#5A47D7',
                    border: '1px solid #7b6cdf',
                  },
                }}
              >
                Load More
              </Button>
            </Flex>
          )
        ) : null}
      </>
    );
  } else if (fetchTalentPoolSearchHistoryRequestStates.pending) {
    nodeToRender = <PageContentLoader mx="auto" />;
  } else if (fetchTalentPoolSearchHistoryRequestStates.rejected) {
    const errorMessage = fetchTalentPoolSearchHistoryRequestStates.error;

    nodeToRender = (
      <Flex direction="column" align="center" justify="center" py="56px">
        <Text>{errorMessage || 'Failed to load page content. Please try again.'}</Text>
        <Button
          mt="lg"
          size="sm"
          onClick={fetchTalentPoolSearchHistory}
          styles={{
            root: {
              background: '#5A47D7',
              border: '1px solid #7b6cdf',
            },
          }}
        >
          Try again
        </Button>
      </Flex>
    );
  }

  return nodeToRender;
};

export default TalentPoolSearchHistoryModal;
