import React from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';

import { useAppContext } from '~/contexts/app';

const LoginRequiredRoute = ({ redirectPath = '/' }) => {
  const location = useLocation();
  const { user } = useAppContext();

  const intermediateRouteSearchParams = new URLSearchParams();
  const nextRouteSearchParams = new URLSearchParams();

  const nextPath = `${location.pathname}?${nextRouteSearchParams.toString()}`;
  intermediateRouteSearchParams.set('next_path', nextPath);

  if (user) {
    return <Outlet />;
  }
  return (
    <Navigate
      to={{
        pathname: redirectPath,
        search: `?${intermediateRouteSearchParams.toString()}`,
      }}
    />
  );
};

export default LoginRequiredRoute;
