import queryString from 'query-string';
import { format } from 'date-fns';

import { DATE_FORMATS } from '~/constants';

import httpClient from './client';

export const fetchTalentPoolInfo = ({ sourcedFrom } = {}, options = {}) => {
  const payload = {};

  if (sourcedFrom) {
    payload.sourcedFrom = sourcedFrom;
  }

  const query = queryString.stringify(payload);
  return httpClient.get(`/api/v1/talent-pool/info?` + query, options);
};

export const fetchCandidateDetailsById = (candidateId, options = {}) => {
  return httpClient.get(`/api/v1/talent-pool/${candidateId}`, options);
};

export const fetchFiltersForTalentPool = (options = {}) => {
  return httpClient.get(`/api/v1/talent-pool/filters/`, options);
};

export const fetchFilterTalentPool = (
  {
    pageSize,
    pageNumber,
    search,
    companies,
    institutions,
    sourcedBy,
    sourcedFrom,
    applicationDateRangeStart,
    applicationDateRangeEnd,
    searchVersion,
  },
  options = {},
) => {
  const queryStr = {
    pageSize,
    pageNumber,
    search: search || undefined,
    companies: companies || undefined,
    institutions: institutions || undefined,
    sourcedBy: sourcedBy || undefined,
    sourcedFrom: sourcedFrom || undefined,
    searchVersion: searchVersion || undefined,
  };

  if (applicationDateRangeStart && applicationDateRangeEnd) {
    queryStr['sourcedAt.comparison'] = 'between';
    queryStr['sourcedAt.values'] = `${format(applicationDateRangeStart, DATE_FORMATS.csvDate)},${format(
      applicationDateRangeEnd,
      DATE_FORMATS.csvDate,
    )}`;
  }

  const query = queryString.stringify(queryStr);

  return httpClient.get(`/api/v1/talent-pool?` + query, options);
};

export const fetchTalentPoolHistory = ({ pageNumber, pageSize }, options = {}) => {
  const query = queryString.stringify({ pageNumber, pageSize });

  return httpClient.get(`/api/v1/talent-pool/search-history/?` + query, options);
};

export const updateTalentPoolHistory = ({ searchHistoryId, payload }, options = {}) => {
  return httpClient.patch(`/api/v1/talent-pool/search-history/${searchHistoryId}`, payload, options);
};
