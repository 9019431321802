import { isProductionHostname } from '~/utils';

const GOOGLE_AUTH_CLIENT_ID_BY_ENVIRONMENT = {
  PRODUCTION: '521387537993-8fs84pvr9s2dh6n1cdedkffp2n5ihrjj.apps.googleusercontent.com',
  STAGING: '137457888237-qkgb7nm9qfkl63baheut9aucvj2f15ls.apps.googleusercontent.com',
  // STAGING: '50999275446-ekmltu8smgp9jkj5qskjea3g43fv3iud.apps.googleusercontent.com',
};

export const GOOGLE_AUTH_CLIENT_ID = (() => {
  const { hostname } = location;
  if (isProductionHostname(hostname)) {
    return GOOGLE_AUTH_CLIENT_ID_BY_ENVIRONMENT.PRODUCTION;
  }
  return GOOGLE_AUTH_CLIENT_ID_BY_ENVIRONMENT.STAGING;
})();
