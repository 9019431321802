import httpClient from './client';

export const fetchUser = (options = {}) => {
  return httpClient.get(`/api/v1/user/`, options);
};

export const loginUser = ({ email, password }, options = {}) => {
  return httpClient.post(`/api/v1/user/login/`, { email, password }, options);
};

export const loginUserWithGoogle = ({ credentialToken }, options = {}) => {
  return httpClient.post(`/api/v1/user/auth/google`, { token: credentialToken }, options);
};
