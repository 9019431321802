import React from 'react';
import styled from '@emotion/styled';
import { Box, Flex, Text } from '@mantine/core';
import { NavLink } from 'react-router-dom';

import KelloLogo from '~/assets/kello-logo.svg';

const Header = () => {
  return (
    <Root as="header">
      <HeaderRoot>
        <Content>
          <BrandDetailsContainer component={NavLink} to="/" display="inline-flex">
            <LogoWrapper>
              <KelloLogo />
            </LogoWrapper>
            <Text ml="4px" fz="24px" fw="650" lh="28px" c="#18161E">
              kello
            </Text>
          </BrandDetailsContainer>
        </Content>
      </HeaderRoot>
    </Root>
  );
};

export default Header;

const Root = styled(Box)`
  height: 76px;
`;

export const HeaderRoot = styled(Box)`
  height: 76px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background: #ffffff;
`;

const Content = styled(Flex)`
  margin: auto;
  max-width: 1272px; // 1272 = 1224 content + 24 padding on left and right
  padding: 24px;
  align-items: center;
`;

export const BrandDetailsContainer = styled(Flex)`
  align-items: center;
  flex-shrink: 0;
  text-decoration: none;
  transform: all 300ms ease-in-out;
`;

export const LogoWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-shrink: 0;
  width: 24px;
  height: 24px;

  > svg {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
  }
`;
